import Rollbar from 'rollbar';
import { createRollbarConfig } from '@shared/utils/rollbar/createRollbarConfig';

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { rollbarClientAccessToken, commitHash, APP_ENV },
  } = useRuntimeConfig();

  const rollbar = new Rollbar(
    createRollbarConfig({
      accessToken: rollbarClientAccessToken,
      commitHash: commitHash,
      environment: APP_ENV,
      scrubFields: ['name', 'email', 'password'],
      // NOTE: CDN経由の外部JSのエラーを受け取らないために設定している
      hostSafeList: [
        'dietplus.jp',
        'b.dietplus.jp',
        // NOTE: ローカルで動かしたくなったとき用に指定しておく
        'kiwi-user.lvh.me',
      ],
    }),
  );

  nuxtApp.vueApp.config.errorHandler = (error) => {
    // NOTE: エラーの型の特定は難しいので as any する
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rollbar.error(error as any);
  };

  return {
    provide: {
      rollbar,
    },
  };
});
