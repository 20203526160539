import validate from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_kcmctokzfu3lapzv6uuhbom4bi/node_modules/nuxt/dist/pages/runtime/validate.js";
import reset_45breadcrumbs_45global from "/app/packages/kiwi-user/middleware/resetBreadcrumbs.global.ts";
import set_45user_45agent_45global from "/app/packages/kiwi-user/middleware/setUserAgent.global.ts";
export const globalMiddleware = [
  validate,
  reset_45breadcrumbs_45global,
  set_45user_45agent_45global
]
export const namedMiddleware = {
  "pages-public-article-dietqa-permalink": () => import("/app/packages/kiwi-user/middleware/pages-public-article-dietqa-permalink.ts"),
  "pages-public-article-dietqa": () => import("/app/packages/kiwi-user/middleware/pages-public-article-dietqa.ts"),
  "pages-public-article-news-permalink": () => import("/app/packages/kiwi-user/middleware/pages-public-article-news-permalink.ts"),
  "pages-public-article-news": () => import("/app/packages/kiwi-user/middleware/pages-public-article-news.ts"),
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.2_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.25.2_reac_mlm2ncqb77edy6d2jbode5ngiq/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}