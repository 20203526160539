export const appHead = {"link":[{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"icon","href":"/apple-touch-icon-152.png","type":"image/png","sizes":"152x152"},{"rel":"icon","href":"/apple-touch-icon-167.png","type":"image/png","sizes":"167x167"},{"rel":"icon","href":"/apple-touch-icon-180.png","type":"image/png","sizes":"180x180"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png","type":"image/png","sizes":"144x144"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"property":"og:type","content":"website"}],"style":[],"script":[{"async":true,"src":"https://platform.twitter.com/widgets.js"},{"async":true,"src":"https://securepubads.g.doubleclick.net/tag/js/gpt.js"},{"src":"https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v19.0","crossorigin":"anonymous","async":true,"defer":true},{"src":"//assets.pinterest.com/js/pinit.js","data-pin-build":"parsePinterest","async":true,"defer":true},{"src":"https://b.st-hatena.com/js/bookmark_button.js","async":true}],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = false

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"